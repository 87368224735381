import React from "react";
import { TextField } from "formik-material-ui";

import "./input-base.scss";

const InputUser = (props) => {
  return (
    <TextField
      type="text"
      {...props}
      inputProps={{ style: { paddingLeft: "100px" } }}
      InputLabelProps={{ style: { paddingLeft: "100px" } }}
    ></TextField>
  );
};

export default InputUser;
