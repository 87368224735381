import React from "react";
import { Tooltip, IconButton, useTheme } from "@material-ui/core";
import "./ConfigBackButton.scss";
import { ArrowBack } from "@material-ui/icons";
const ConfigBackButton = (props) => {
  const { setLoginForm } = props;
  const theme = useTheme();
  return (
    <>
      <Tooltip title={"Volver a Login"}>
        <span>
          <IconButton onClick={() => setLoginForm()}>
            <ArrowBack style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default ConfigBackButton;
