import React, { useState } from "react";
import "./login.scss";
import LoginProgress from "./progress/login-progress.component";
import ConfigDrawerButton from "./config-drawer/config-drawer-button.component";
import { useSnackbar } from "notistack";
import LoginForm from "./forms/LoginForm";
import SidebarConfig from "./config-sidebar/SidebarConfig";
import "semantic-ui-css/semantic.min.css";
import { Segment, Sidebar } from "semantic-ui-react";
import ConfigBackButton from "./config-drawer/ConfigBackButton";
import * as AuthTypes from "../model/authTypes";
import AuthForm from "./forms/AuthForm";

const key = "auth-type";

export default function SignIn(props) {
	const { config, redirectToWeb } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
	const [authType, setAuthType] = useState(
		localStorage.getItem(key) ?? AuthTypes.LOCAL
	);

	const setLoginForm = () => {
		setIsLoginFormVisible(true);
	};

	const setAuthForm = () => {
		setIsLoginFormVisible(false);
	};

	React.useEffect(() => {
		if (errorMessage) {
			enqueueSnackbar(errorMessage, { variant: "error" });
		}
	}, [enqueueSnackbar, errorMessage]);

	return (
		<>
			<div className="bg-white rounded login-paper ml-auto mr-auto">
				<div className="row no-gutters w-100">
					<div id="drawer-container" className="col-12 w-100">
						<Sidebar.Pushable as={Segment}>

							<SidebarConfig
								open={open}
								setOpen={setOpen}
								setAuthForm={setAuthForm}
							/>
							<Sidebar.Pusher>
								<Segment basic>
									<div className="row no-gutters w-100">

										<div
											className="d-flex w-100 justify-content-center"
											style={{ height: "130px" }}
										>
											<img
												className="img-dimension"
												src={`${process.env.PUBLIC_URL}/${config.LOGIN.COMPANY_IMG}`}
												alt="LOGO CLIENTE"
											></img>
										</div>
										{!isLoginFormVisible ? (
											<div className="back-button">
												<ConfigBackButton
													setLoginForm={setLoginForm}
												/>
											</div>
										) : null}
										<div className="login-button">
											<ConfigDrawerButton
												open={open}
												setOpen={setOpen}
											/>
										</div>
									</div>
									{isLoginFormVisible ? (
										<LoginForm
											config={config}
											authType={authType}
											setErrorMessage={setErrorMessage}
											setLoading={setLoading}
											redirectToWeb={redirectToWeb}
										/>
									) : null}
									{!isLoginFormVisible ? (
										<AuthForm
											authType={authType}
											setAuthType={setAuthType}
											setLoginForm={setLoginForm}
										/>
									) : null}
								</Segment>
							</Sidebar.Pusher>
						</Sidebar.Pushable>
						<LoginProgress loading={loading} />
					</div>
				</div>
			</div>
		</>
	);
}
