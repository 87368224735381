import React from "react";
import { TextField } from "formik-material-ui";
import "./input-base.scss";

const InputPassword = (props) => {
  return (
    <TextField
      type={"password"}
      {...props}
      inputProps={{ style: { paddingLeft: "100px" } }}
      InputLabelProps={{ style: { paddingLeft: "100px" } }}
    ></TextField>
  );
};

export default InputPassword;
