import React from "react";
import moment from "moment";
import SignIn from "./components/login.component";
import { useCookies } from "react-cookie";
import { getTokenInfo } from "./shared/tokenUtils";
import { RootErrorBoundary } from "@snc/ui";
import { EmiConfigProvider, useEmiConfig } from "@snc/emi-config";
import { ActionProgressReport } from "@snc/ui";
import Axios from "axios";
import "./App.css";
import { ThemeProvider } from "./components/Theme/ThemeProvider";

export default function App() {
	return (
		//
		<RootErrorBoundary
			baseUrl={process.env.PUBLIC_URL}
			buttonProps={{
				style: { backgroundColor: "#FF4347", color: "#FFF" },
			}}
			iconProps={{ style: { color: "#FF4347" } }}
		>
			<EmiConfigProvider>
				<AppInner />
			</EmiConfigProvider>
		</RootErrorBoundary>
	);
}

function AppInner() {
	const [cookies, setCookie] = useCookies(["token"]);

	const [isLoading, setLoading] = React.useState(true);
	const [redirectUri, setRedirectUri] = React.useState(null);

	const config = useEmiConfig();

	const redirectToWeb = React.useCallback(
		(redirectUri) => window.location.replace(redirectUri),
		[]
	);

	React.useEffect(() => {
		if (config?.LOGIN?.BG_IMG)
			document.body.style = `background-image: url("${process.env.PUBLIC_URL}/${config.LOGIN.BG_IMG}") !important;`;
	}, [config?.LOGIN?.BG_IMG]);

	React.useEffect(() => {
		const refreshToken = () =>
			Axios.post(`${config.AUTH.BASE}/refresh-token/refresh`);

		const params = window.location.search;
		const searchParams = new URLSearchParams(params);

		let redirectUri = config.LOGIN.REDIRECT;

		if (searchParams.has("redirect")) {
			redirectUri = params.replace("?redirect=", "").trim();
		}

		setRedirectUri(redirectUri);

		const token = cookies["token"];

		if (token) {
			if (getTokenInfo(token).exp > moment()) {
				return redirectToWeb(redirectUri);
			}
		}
		// If there is no token, or the token expired, try to refresh-token. Continue to login if refresh-token expired too
		refreshToken()
			.then(({ data }) => {
				setCookie("token", data.token, {
					path: "/",
					// sameSite: "none",
					// secure: true,
				});
				redirectToWeb(redirectUri);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading)
		return (
			<div
				className="w-100 d-flex justify-content-center align-items-center"
				style={{ height: "100vh" }}
			>
				<div className=".login-paper-loading bg-white rounded">
					<ActionProgressReport />
				</div>
			</div>
		);
	else
		return (
			<ThemeProvider>
				<div className="container login-container justify-content-center d-flex flex-column">
					<div className="row no-gutters row-logo">
						<img
							src={`${process.env.PUBLIC_URL}/assets/emi-logo.svg`}
							alt="LOGO"
						/>
					</div>
					<SignIn
						config={config}
						redirectToWeb={() => redirectToWeb(redirectUri)}
					/>
				</div>
			</ThemeProvider>
		);
}
