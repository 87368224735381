import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Sidebar } from "semantic-ui-react";
import "./SidebarConfig.scss";

const SidebarConfig = (props) => {
	const { open, setOpen, setAuthForm } = props;

	const onAuthClick = () => {
		setAuthForm();
		setOpen(false);
	};
	return (
		<Sidebar
			className="sidebar-config"
			animation="overlay"
			onHide={() => setOpen(false)}
			direction="right"
			visible={open}
			width="thin"
		>
			<List className="sidebar-list">
				<ListItem button onClick={() => onAuthClick()}>
					<ListItemText
						className="sidebar-list-item"
						primary="Autenticación"
					></ListItemText>
				</ListItem>
			</List>
		</Sidebar>
	);
};

export default SidebarConfig;
