import React, { useEffect } from "react";
import { MenuItem, Button, makeStyles } from "@material-ui/core";
import * as authTypes from "../../model/authTypes";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import "../form-inputs/input-base.scss";

const AuthForm = (props) => {
  const { authType, setAuthType, setLoginForm } = props;

  return (
    <div className="row no-gutters w-100">
      <Formik
        initialValues={{
          authType: authType,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setAuthType(values.authType);
          setLoginForm();
        }}
        component={(formikProps) => (
          <AuthInnerForm authType={authType} {...formikProps} />
        )}
      ></Formik>
    </div>
  );
};

const AuthInnerForm = (props) => {
  const { authType, setFieldValue } = props;
  useEffect(() => {
    setFieldValue("authType", authType);
  }, [authType, setFieldValue]);
  const classes = useStyles();
  return (
    <Form className="w-100 h-100">
      <div className="row no-gutters w-100 pt-2">
        <Field
          fullWidth
          required
          type="text"
          name="authType"
          label={"Tipo"}
          variant="outlined"
          component={TextField}
          // inputProps={{ style: { paddingLeft: "100px" } }}
          InputLabelProps={{ style: { paddingLeft: "100px" } }}
          select
        >
          {Object.keys(authTypes).map((authType, index) => (
            <MenuItem key={index} value={authType}>
              {authType}
            </MenuItem>
          ))}
        </Field>
      </div>
      <div className="d-flex align-items-center justify-content-center pt-4 pb-4">
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          autoFocus
          disableFocusRipple
        >
          <span className="font-weight-bold">APLICAR</span>
        </Button>
      </div>
    </Form>
  );
};

export default AuthForm;


const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none !important',
    width: '38%',
    position: 'fixed !important',
    bottom: '25px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
}));
