import React from "react";
import { Tooltip, IconButton, useTheme } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import "./config-drawer-button.scss";
const ConfigDrawerButton = (props) => {
  const { open, setOpen } = props;

  const theme = useTheme();
  return (
    <>
      <Tooltip title={"Configuración"}>
        <span>
          <IconButton onClick={() => setOpen(!open)}>
            <SettingsIcon style={{ color: open ? ' ' : theme.palette.primary.main }} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default ConfigDrawerButton;
