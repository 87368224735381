import React from "react";
import { LinearProgress } from "@material-ui/core";

const LoginProgress = (props) => {
  const { loading } = props;
  const value = 100;
  return (
    <>
      {!loading ? (

        <LinearProgress variant={"determinate"} value={value} />
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default LoginProgress;
